<template>
  <div class="address">
    <el-dialog title="" :visible.sync="dialogFormVisible" center width="800px">
      <div class="address-list-container">
        <div class="add-address-header">
          <div class="address-card">
            <el-form>
              <el-form-item label="收货人" label-width="100px">
                <el-input v-model="create_address.consignee" placeholder="收货人" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="手机号码" label-width="100px">
                <el-input v-model="create_address.phone" placeholder="手机号码" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="地址" label-width="100px">
                <el-cascader v-model="create_address.citys" :options="cityList" @change="handleChangeAddress"></el-cascader>
              </el-form-item>
              <el-form-item label="详细地址" label-width="100px">
                <el-input v-model="create_address.address" placeholder="详细地址" autocomplete="off"></el-input>
              </el-form-item>
            </el-form>
            <div class="button-container">
              <button class="add-address-button" id="openAddressModal" @click="createAddress">新增收货地址</button>
            </div>
          </div>
          <!-- <span class="address-tip">您已创建2个收货地址，最多可创建25个</span> -->
        </div>
        <div id="address-card-container">
          <!-- 地址卡片 -->
          <div v-for="(address, index) in user_address_list" :key="index">
            <div class="address-card">
              <el-row>
                <el-col :span="4">
                  <el-checkbox
                    v-model="address.checked"
                    class="address-checkbox"
                    :checked="address.checked"
                    @change="changeAddressChecked(address.id, $event)"></el-checkbox>
                </el-col>
                <el-col :span="20">
                  <div>
                    <div class="address-header">
                      <span class="address-name">{{ address.consignee }}</span>
                      <span class="address-region">{{ address.phone }}</span>
                      <span class="address-default" v-if="address.is_default == 1">默认地址</span>
                    </div>
                    <div class="address-content">
                      <div class="property_name">
                        <p>收货人:</p>
                        <p>电话:</p>
                        <p>所在地区:</p>
                        <p>详细地址:</p>
                      </div>
                      <div class="property_value">
                        <p>{{ address.consignee }}</p>
                        <p>{{ address.phone }}</p>

                        <p>{{ address.province_name }}-{{ address.city_name }}-{{ address.area_name }}</p>
                        <p>{{ address.address }}</p>
                      </div>
                    </div>
                    <div class="button-container">
                      <button class="set-default" @click="updateDefaultAddress(address)" v-if="address.is_default == 0">设置为默认地址</button>
                      <!-- <button class="edit_button">编辑地址</button> -->
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="danger" @click="changeAddress" :loading="successLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from "@/libs/util";
import { getCityListApi } from "@/api/product";
import { saveUserAddressApi, getUserAddressListApi, updateDefaultAddressApi } from "@/api/user";

export default {
  name: "Address",

  data() {
    return {
      successLoading: false,
      dialogFormVisible: false,
      is_login: false,
      create_address: {
        citys: [],
        consignee: "",
        phone: "",
        province_name: "",
        city_name: "",
        area_name: "",
        address: ""
      },
      cityList: [],
      user_address_list: [],
      address: {},
      address_id: ""
    };
  },
  mounted() {
    this.getCityList();
  },
  methods: {
    getCityList() {
      getCityListApi().then((res) => {
        const list = [];
        res.forEach((element) => {
          const lt = [];
          element.children.forEach((items) => {
            const arr = [];
            items.children.forEach((item) => {
              arr.push({ value: item.title, label: item.title });
            });
            lt.push({ children: arr, value: items.title, label: items.title });
          });
          list.push({
            children: lt,
            value: element.title,
            label: element.title
          });
        });
        this.cityList = list;
      });
    },

    openModel(address_id) {
      this.address_id = address_id;

      var token = util.cookies.get("token");
      if (token) {
        this.is_login = true;
      } else {
        this.is_login = false;
        this.$message.error("请登录后选择地址");
        return;
      }
      this.getUserAddressList();
      this.dialogFormVisible = true;
    },
    getUserAddressList() {
      getUserAddressListApi().then((res) => {
        this.user_address_list = res;
        this.changeAddressChecked(this.address_id, true);
      });
    },
    changeAddress() {
      var user_address_list = this.user_address_list;
      var address = {
        id: "",
        consignee: "",
        phone: "",
        province_name: "",
        city_name: "",
        area_name: "",
        address: ""
      };
      user_address_list.forEach((element) => {
        if (element.checked) {
          address = element;
        }
      });
      this.$emit("changeAddress", address);

      this.dialogFormVisible = false;
    },
    handleChangeAddress(e) {
      this.create_address.province_name = e[0];
      this.create_address.city_name = e[1];
      this.create_address.area_name = e[2];
    },
    createAddress() {
      //       consignee: "",
      // phone: "",
      // province_name: "",
      // city_name: "",
      // area_name: "",
      // address: ""
      if (!this.create_address.consignee) {
        this.$message.error("请选择收货人");
        return;
      }
      if (!this.create_address.phone) {
        this.$message.error("请输入手机号码");
        return;
      }
      if (!this.create_address.province_name) {
        this.$message.error("请选择地址");
        return;
      }
      if (!this.create_address.address) {
        this.$message.error("请输入详细地址");
        return;
      }
      saveUserAddressApi(this.create_address).then((res) => {
        this.$message.success("添加成功");
        this.getUserAddressList();
        this.create_address = {
          citys: [],
          consignee: "",
          phone: "",
          province_name: "",
          city_name: "",
          area_name: "",
          address: ""
        };
      });
    },
    updateDefaultAddress(address) {
      updateDefaultAddressApi({ id: address.id }).then((res) => {
        this.getUserAddressList();

        this.$message.success("修改成功");
      });
    },
    changeAddressChecked(address_id, e) {
      var user_address_list = this.user_address_list;

      if (e) {
        for (let index = 0; index < user_address_list.length; index++) {
          const element = user_address_list[index];
          if (element.id == address_id) {
            user_address_list[index].checked = true;
          } else {
            user_address_list[index].checked = false;
          }
        }
      } else {
        for (let index = 0; index < user_address_list.length; index++) {
          user_address_list[index].checked = false;
        }
      }
      this.user_address_list = JSON.parse(JSON.stringify(user_address_list));
      // this.user_address_list = user_address_list;
    }
  }
};
</script>

<style>
.address-list-container {
  /* position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 500px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  margin-top: 20px; */
}

.address-card h3 {
  text-align: left;
  color: #333;
  margin-bottom: 10px;
}
.address-checkbox {
  width: 20%;
}

.address-card p {
  text-align: left;
  color: #666;
  line-height: 1.5;
  margin-bottom: 5px;
}
.address-card {
  /* display: flex; */
  /* flex-direction: column; */
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 10px;
}

.address-content {
  display: flex;
}

.property_name {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.property_value {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.property_name p {
  color: gray;
  text-align: right;
  margin: 4px 0; /* 根据需要调整间距 */
  /* 其他样式 */
}

.property_value p {
  color: #5d5f60;
  margin: 4px 0; /* 调整间距 */
}

.button-container {
  display: flex;
  justify-content: flex-end; /* 将按钮放置在右侧 */
  align-items: center; /* 垂直居中对齐按钮 */
}

.set-default,
.edit_button {
  width: auto; /* 自适应宽度 */
  margin-left: 10px; /* 为按钮之间添加一些间距 */
  color: #0000ff;
  background: transparent;
  border: none; /* 去掉按钮的边框 */
  outline: none; /* 去掉按钮的点击边框 */
  cursor: pointer;
}

.set-default:hover {
  color: red;
}
.edit_button:hover {
  color: red;
}

.add-address-header {
}

.add-address-button {
  background-color: #fd4047;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.add-address-button:hover {
  background-color: #e55;
}

.address-tip {
  position: absolute;
  left: 200px;
  font-size: 14px;
  color: #666;
}

.address-card {
  /* display: flex; */
  /* flex-direction: column; */
  /* 其他样式 */
}

.address-header {
  display: flex;
  align-items: center;
  font-size: 18px; /* 或其他适合您设计的大小 */
  margin-bottom: 15px;
  color: #696565;
  font-weight: bold;
  /* 根据需要添加其他样式，如内边距、边距等 */
}

.address-name,
.address-region {
  font-size: 18px;
  margin-right: 10px; /* 添加适当的右边距 */
}

.address-default {
  color: white;
  background-color: #fd4047; /* 浅灰色背景 */
  border-radius: 2px; /* 圆角边框 */
  padding: 0px 6px; /* 内边距 */
  font-size: 0.8em; /* 较小的字体尺寸 */
  margin-left: 10px; /* 如果需要在默认地址和地区标签之间添加空间 */
  white-space: nowrap; /* 防止文本折行 */
}

.address-card {
  position: relative; /* 为子元素的绝对定位提供上下文 */
  /* 其他已有的样式 */
}

.remove-address-button {
  color: gray;
  position: absolute; /* 绝对定位 */
  top: 10px; /* 距离容器顶部的距离 */
  right: 10px; /* 距离容器右侧的距离 */
  background: transparent; /* 透明背景 */
  border: none; /* 无边框 */
  font-size: 20px; /* 字体大小，根据需要调整 */
  cursor: pointer; /* 鼠标悬停时的指针形状 */
}

.remove-address-button:hover {
  color: #f00; /* 鼠标悬停时的字体颜色，这里使用红色作为示例 */
}

.modal {
  display: none; /* 默认隐藏弹窗 */
  position: fixed; /* 固定定位 */
  z-index: 2; /* 在顶层 */
  left: 0;
  top: 0;
  width: 100%; /* 全屏宽 */
  height: 100%; /* 全屏高 */
  background-color: rgba(94, 92, 92, 0.4); /* 半透明背景 */
  padding-top: 100px; /* 从顶部开始的内边距 */
}

.modal-content {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  height: 70%;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 24px;
  font-weight: bold;
  position: absolute; /* Use absolute positioning */
  top: -13px; /* Align to the top */
  right: -5px; /* Align to the right */
  padding: 10px; /* Add some space around the button for easier clicking */
  cursor: pointer;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.error-message {
  display: none;
  color: red;
  margin-left: 5px;
  font-size: 0.8em;
}

.distpicker select {
  height: 30px;
  border-radius: 3px;
}
.addressForm input {
  padding-left: 8px;
  height: 30px;
}
.addressForm label {
  display: inline-block;
  color: #71797f;
  margin-bottom: 5px;
}
#address {
  width: 325px;
}
.label-required::before {
  content: "*";
  color: red;
  margin-right: 4px;
}
.modal-header {
  padding: 10px;
  background-color: #91919b;
  color: white;
  font-size: 20px;
  text-align: center;
  margin-bottom: 40px;
}
</style>
