<template>
  <div class="index">
    <el-container>
      <el-header class="header">
        <el-row class="flex_align_center">
          <el-col :span="4">
            <!-- <div>LOGO</div> -->
            <img class="logo" src="@/assets/images/logo.png" alt="" srcset="" />
          </el-col>
          <el-col :span="16">
            <el-input
              @change="getProductList"
              style="width: 500px"
              placeholder="请输入搜索"
              suffix-icon="el-icon-search"
              v-model="search_product_data.title"></el-input>
          </el-col>
          <el-col :span="4"><Login ref="login"></Login></el-col>
        </el-row>
      </el-header>
      <div class="flex-layout">
                    <div class="menu">
              <el-menu :default-active="search_product_data.store_id" class="el-menu-vertical-demo" @select="selectStore">
                <el-menu-item :index="item.id + ''" v-for="(item, index) in storeList" :key="index">
                  <span slot="title">{{ item.store_name }}</span>
                </el-menu-item>
              </el-menu>
              <el-pagination
                @current-change="onChangeStorePage"
                layout="prev, pager, next"
                small
                :total="storeListCount"
                v-if="storeListCount > 0"></el-pagination>
            </div>
                        <div class="product">
              <div class="product_container">
                <div v-for="(item, index) in productList" :key="index" class="card">
                  <el-carousel :autoplay="false" trigger="click" height="300px">
                    <el-carousel-item v-for="(it, i) in item.info.slider_image" :key="i">
                      <img class="slider_image_picture" :src="it" />
                    </el-carousel-item>
                  </el-carousel>
                  <div class="card__details">
                    <div class="product_title">{{ item.title }}</div>
                    <div class="product_price">￥ {{ item.exhibit_price }}</div>
                    <!-- <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Consectetur sodales morbi dignissim sed diam pharetra vitae ipsum odio.
                    </p> -->
                    <div class="btn">
                      <button class="purchase_btn" @click="openProduct(item.id)">立即购买</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex_justify_end">
                <el-pagination
                  background
                  @current-change="onChangeProductPage"
                  layout="prev, pager, next"
                  :total="productListCount"
                  v-if="productListCount > 0"></el-pagination>
              </div>
            </div>

      </div>
    </el-container>
    <Product ref="Product"></Product>
  
  </div>
</template>

<script>
import { getStoreListApi, getProductListApi } from "@/api/product";

import Login from "@/components/Login.vue";
import Product from "@/components/Product.vue";

import { Loading } from "element-ui";
export default {
  name: "IndexView",
  components: {
    Login,
    Product
  },
  data() {
    return {
      search_product_data: {
        page: 1,
        limit: 10,
        store_id: "",
        title: ""
      },
      search_store_data: {
        page: 1,
        limit: 10
      },
      storeList: [],
      storeListCount: 0,
      productList: [],
      productListCount: 0
    };
  },
  mounted() {
    this.getStoreList();
  },
  methods: {
    getStoreList() {
      var storeLoading = Loading.service();
      getStoreListApi(this.search_store_data)
        .then((res) => {
          storeLoading.close();
          if (res.list.length > 0) {
            this.search_product_data.store_id = String(res.list[1].id);
            this.getProductList();
          }
          this.storeList = res.list;
          this.storeListCount = res.count;
        })
        .catch((err) => {
          storeLoading.close();
        });
    },
    getProductList() {
      var productLoading = Loading.service();
      getProductListApi(this.search_product_data)
        .then((res) => {
          productLoading.close();
          this.productList = res.list;
          this.productListCount = res.count;
        })
        .catch((err) => {
          productLoading.close();
        });
    },
    onChangeStorePage(e) {
      this.search_store_data.page = e;
      this.getStoreList();
    },
    onChangeProductPage(e) {
      this.search_product_data.page = e;
      this.getStoreList();
    },
    selectStore(e) {
      this.search_product_data.store_id = e;
      this.getProductList();
    },
    openProduct(product_id) {
      this.$refs.Product.openModel(product_id,this.search_product_data.store_id);
    }
  }
};
</script>

<style>
.header {
  /* text-align: right; */
  font-size: 20px;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}
/* .search_input {
  width: 300px;
} */
.menu {
  width: 300px;
}
.product_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  /* max-width: 300px; */
  /* margin: 0 auto; */
  margin-top: 20px;
}
/* .product{
  display: flex;
} */
.card {
  background-color: white;
  border: 1px solid #bacdd8;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  margin: 10px 5px;
  width: 300px;
}
.product_item {
  width: 20%;
  height: 400px;
  border: 1px solid #ccc;
}
.slider_image_picture {
  width: 100%;
  object-fit: cover;
}
/* 给具有 name class 的 div 元素添加样式 */
.name {
  font-size: 20px;
  font-weight: 600;
}

/* 给 p 元素添加样式 */
p {
  font-size: 14px;
  color: #7f8c9b;
  line-height: 150%;
}

.card__details {
  /* 在细节内容周围添加空白区域 */
  padding: 16px 8px 8px 8px;
}
.product_title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 50px;
}
.product_price {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
  font-weight: 600;
  color: #fc3e46;
  margin-bottom: 20px;
  padding-right: 10px;
}
/* 给 button 元素添加样式 */
.btn {
  display: flex;
  justify-content: flex-end;
}
.purchase_btn {
  border: none;
  padding: 12px 24px;
  border-radius: 50px;

  font-weight: 600;
  /* color: #fc3e46; */
  color: #fff;
  background-color: #fc3e46;

  /* margin: 0 auto; */
  /* display: block; */

  cursor: pointer;
}
.logo {
  height: 50px;
}
</style>
