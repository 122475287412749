<template>
  <div class="payCode" v-if="dialogFormVisible">
    <el-dialog title="" :visible.sync="dialogFormVisible" center width="530px">
      <iframe
        :srcdoc="form"
        frameborder="no"
        border="0"
        marginwidth="0"
        marginheight="0"
        scrolling="no"
        width="500"
        height="600"
        style="overflow: hidden"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { payStateApi } from "@/api/product";

export default {
  data() {
    return {
      form: {},
      dialogFormVisible: false,
      intervalwx: null,
      order_sn: "",
      payState: false
    };
  },
  watch: {
    dialogFormVisible: {
      handler(newVal, oldVal) {
        if (!newVal) {
          clearInterval(this.intervalwx);

          this.intervalwx = null;

          if (!this.payState) {
            this.$emit("paySuccess", "close");
          }
        }
      },
      deep: true
    }
    // wechat() {
    //   this.intervalwx = window.setInterval(() => {
    //     setTimeout(this.paySuccess(), 0);
    //   }, 1000);
    // }
  },
  methods: {
    openModel(form, order_sn) {
      this.dialogFormVisible = true;
      this.payState = false;

      this.form = form;
      this.order_sn = order_sn;
      this.paySuccess();
      this.intervalwx = setInterval(() => {
        this.paySuccess();
      }, 1000);
    },
    //支付宝支付
    paySuccess() {
      payStateApi({ order_sn: this.order_sn }).then((res) => {
        if (res && res.state == "TRADE_SUCCESS") {
          clearInterval(this.intervalwx);
          this.dialogFormVisible = false;
          this.intervalwx = null;
          this.$emit("paySuccess", "success");
          this.payState = true;
        }
      });
    }
  }
};
</script>

<style scoped></style>
