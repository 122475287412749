<template>
  <div>
    <div @click="openModel" class="cursor-pointer" v-if="!is_login">登录</div>
    <div class="flex_align_center" v-else>
      <div>
        {{ encryptPhone }}
      </div>
      <el-tag type="danger" @click="signOut" class="cursor-pointer sign_out">退出登录</el-tag>
    </div>

    <div class="login" v-if="dialogFormVisible">
      <el-dialog title="登录" :visible.sync="dialogFormVisible" center width="500px">
        <el-form>
          <el-form-item label="手机号码" label-width="100px">
            <el-input v-model="form.phone" placeholder="手机号码" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="短信验证码" prop="phone_code" class="pr" label-width="100px">
            <el-input placeholder="短信验证码" v-model="form.phone_code"></el-input>
            <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
              <span v-show="show">获取验证码</span>
              <span v-show="!show" class="count">{{ count }} s</span>
            </button>
          </el-form-item>
          <!-- <el-form-item label="活动名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="活动区域" :label-width="formLabelWidth">
          <el-select v-model="form.region" placeholder="请选择活动区域">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="login" :loading="loginLoading">登录</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { userLogin, getUserLoginCode } from "@/api/user";
import util from "@/libs/util";

export default {
  name: "Login",
  props: {
    msg: String
  },
  data() {
    return {
      dialogFormVisible: false,
      form: {
        phone: "",
        phone_code: ""
      },
      count: 0,
      timer: false,
      show: true,
      loginLoading: false,
      is_login: false,
      userPhone: "",
      encryptPhone: ""
    };
  },
  mounted() {
    //判断是否登录
    var token = util.cookies.get("token");

    if (token) {
      this.is_login = true;
      this.userPhone = util.cookies.get("userPhone");
      this.encryptPhone = this.userPhone.slice(0, 3) + "****" + this.userPhone.slice(7);
    }
  },
  methods: {
    openModel() {
      this.dialogFormVisible = true;
    },
    closedialogFormVisible() {
      this.dialogFormVisible = false;
    },
    signOut() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          util.cookies.remove("token");
          util.cookies.remove("userId");
          util.cookies.remove("userPhone");

          this.is_login = false;

          this.$message({
            type: "success",
            message: "退出成功!"
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消退出登录"
          // });
        });
    },
    // 获取短信验证码
    getCode() {
      //axios请求
      if (!this.form.phone) {
        this.$message.error("请输入手机号码");
        return;
      }
      getUserLoginCode({ phone: this.form.phone }).then((res) => {
        this.$message.error("验证码已发送");

        this.startCountdown();
      });
    },
    startCountdown() {
      // 验证码倒计时
      if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    login() {
      if (!this.form.phone) {
        this.$message.error("请输入手机号码");
        return;
      }
      if (!this.form.phone_code) {
        this.$message.error("请输入验证码");
        return;
      }
      this.loginLoading = true;

      userLogin(this.form)
        .then((res) => {
          this.$message.success("登录成功");
          this.loginLoading = false;

          let expires = res.expires_time;

          util.cookies.set("token", res.token, {
            expires: expires
          });
          util.cookies.set("userId", res.user_info.id, {
            expires: expires
          });
          util.cookies.set("userPhone", res.user_info.phone, {
            expires: expires
          });
          this.userPhone = res.user_info.phone;
          this.encryptPhone = this.userPhone.slice(0, 3) + "****" + this.userPhone.slice(7);

          this.dialogFormVisible = false;
          this.is_login = true;
        })
        .catch((err) => {
          this.loginLoading = false;
        });
    }
  }
};
</script>

<style scoped>
/* 短信验证码css */
.pr {
  position: relative;
}
.code-btn {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 222;
  color: #ef8466;
  font-size: 14px;
  border: none;
  border-left: 1px solid #bababa;
  padding-left: 10px;
  background-color: #fff;
  cursor: pointer;
}
.sign_out {
  margin-left: 20px;
}
</style>
